import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  unstable_useEnhancedEffect as useEnhancedEffect,
} from "@mui/material";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import type { MainSchema, SettingsSchema } from "./schemas";
import type { ProductJson } from "./jsonSchemas";
import { register } from "swiper/element/bundle";
import { withEmotionCache } from "@emotion/react";
import { useContext } from "react";
import ClientStyleContext from "./ClientStyleContext";
import getTheme from "./theme";
import { getSession } from "./sessions.server";
import { ClientOnly } from "remix-utils/client-only";
import ScrollToTop from "./components/ScrollToTop";
import styles from "./root.module.css";
import { getLanguageFromPath } from "~/i18next.server";
import { useChangeLanguage } from "remix-i18next/react";
import { useTranslation } from "react-i18next";
import { getMainData, getProductsData } from "./utils.server";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { roRO } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

// register Swiper custom elements
register();

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  i18n: "translation",
};

export async function loader({ context, request }: LoaderFunctionArgs) {
  const env = context.cloudflare.env;

  const settings = (await fetch(env.DTW_SETTINGS_URL).then((r) =>
    r.json()
  )) as SettingsSchema;

  const lng = getLanguageFromPath(request);

  const [main, products, uId, locale] = await Promise.all([
    getMainData(request, env.DTW_SETTINGS_URL, env.DTW_BASE_URL),
    getProductsData(request, env.DTW_SETTINGS_URL, env.DTW_BASE_URL),
    getSession(request.headers.get("Cookie")).then((s) => s.get("userId")),
    Promise.resolve(lng),
  ]);

  const obj = {
    main: main as MainSchema,
    products: products as ProductJson[],
    settings: settings,
    userId: uId,
    woopraProject: env.DTW_WOOPRA_PROJECT,
    locale,
  };
  return json(obj);
}

export const links: LinksFunction = () => {
  return [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/icon?family=Material+Icons",
    },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap",
    },
  ];
};

export const Layout = withEmotionCache(
  ({ children }: { children: React.ReactNode }, emotionCache) => {
    const data = useRouteLoaderData<typeof loader>("root");
    const theme = getTheme(data?.main);
    const { i18n } = useTranslation();
    const locale = data?.locale ?? "ro-RO";
    useChangeLanguage(locale);
    const clientStyleData = useContext(ClientStyleContext);

    // Only executed on client
    useEnhancedEffect(() => {
      // re-link sheet container
      emotionCache.sheet.container = document.head;
      // re-inject tags
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
        (emotionCache.sheet as any)._insertTag(tag);
      });
      // reset cache to reapply global styles
      clientStyleData.reset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <html lang={locale} dir={i18n.dir()} suppressHydrationWarning>
        <head>
          <script src="/cdn-cgi/zaraz/i.js" referrerPolicy="origin"></script>
          <meta httpEquiv="Content-Type" content="text/html;charset=utf-8" />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <meta
            name="emotion-insertion-point"
            content="emotion-insertion-point"
          />
          <Links />
        </head>
        <body style={styles}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              localeText={
                roRO.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              {children}
              <ClientOnly>{() => <ScrollToTop />}</ClientOnly>
            </LocalizationProvider>
          </ThemeProvider>
          <ScrollRestoration />
          <Scripts />
          {process.env.NODE_ENV === "development" ||
          !data?.woopraProject ? null : (
            <script
              dangerouslySetInnerHTML={{
                __html: `
              !function(){var t,o,c,e=window,n=document,r=arguments,a="script",i=["call","cancelAction","config","identify","push","track","trackClick","trackForm","update","visit"],s=function(){var t,o=this,c=function(t){o[t]=function(){return o._e.push([t].concat(Array.prototype.slice.call(arguments,0))),o}};for(o._e=[],t=0;t<i.length;t++)c(i[t])};for(e.__woo=e.__woo||{},t=0;t<r.length;t++)e.__woo[r[t]]=e[r[t]]=e[r[t]]||new s;(o=n.createElement(a)).async=1,o.src="https://static.woopra.com/js/w.js",(c=n.getElementsByTagName(a)[0]).parentNode.insertBefore(o,c)}("woopra");

              woopra.config({
                domain: "${data.woopraProject}",
                outgoing_tracking: true,
                download_tracking: true,
                click_tracking: true
              });

              woopra.track();
            `,
              }}
            ></script>
          )}
        </body>
      </html>
    );
  }
);

export function ErrorBoundary() {
  const error = useRouteError();
  let errorTitle = "We're sorry, an unexpected error has occured.";
  let errorSubtitle =
    "This problem has been automatically reported. See the latest offers in our store.";
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorTitle = "We're sorry, the page you are looking for cannot be found.";
      errorSubtitle = "See the latest offers in our store.";
    }
  }

  captureRemixErrorBoundaryError(error);

  return (
    <Box
      sx={{
        m: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100dvh",
      }}
    >
      <Typography variant="h4">{errorTitle}</Typography>
      <Typography variant="h4" sx={{ marginBottom: (t) => t.spacing(2) }}>
        {errorSubtitle}
      </Typography>
      <Link to="/">
        <Button variant="contained">Go to store</Button>
      </Link>
    </Box>
  );
}

function App() {
  return <Outlet />;
}
export default withSentry(App);
